<template>
    <!-- <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition"> -->
    <v-dialog v-model="dialog" transition="dialog-bottom-transition">
        <template v-slot:activator="{ on, attrs }">
            <v-btn small text outlined color="error" class="lightError mr-2" v-bind="attrs" v-on="on"
                v-if="isBtnAccepted === false">
                Reject
            </v-btn>
            <v-btn small color="primary" v-bind="attrs" v-on="on" v-if="isBtnAccepted === true">
                Accept
            </v-btn>
        </template>
        <v-card tile flat>
            <v-toolbar color="" elevation="0" class="primaryAccent">
                <v-btn color="error" class="lightError" icon @click="dialog = false">
                    <v-icon>{{ icons.mdiClose }} </v-icon>
                </v-btn>
                <v-toolbar-title>Request response</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-btn dark text @click="dialog = false">
                        Save
                    </v-btn>
                </v-toolbar-items>
            </v-toolbar>
            <v-list three-line subheader>
                <v-subheader>Reponses description</v-subheader>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>Describe any things</v-list-item-title>
                        <v-list-item-subtitle>
                            <vue-editor v-model="rfpComment" outlined dense class="text-left mb-3" label="Body"
                                placeholder="Description of your acceptation">
                            </vue-editor>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <div v-if="isBtnAccepted === true">
                <v-divider></v-divider>
                <v-list three-line subheader>
                    <v-subheader>Attach others documents</v-subheader>
                    <!-- <v-list-item>
                    <v-list-item-action>

                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>#</v-list-item-title>
                    </v-list-item-content>
                </v-list-item> -->
                </v-list>
            </div>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>

                <!-- <v-btn color="error" class="px-4" text @click="sentPropositionResponse">
                cancel response
                </v-btn> -->
                <v-btn class="secondary px-4" @click="sentPropositionResponse" :loading="loadingReplyBtn">
                    {{ this.isBtnAccepted === true ? 'Confirm acceptation' : 'Sent response' }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

</template>
<script>
import { VueEditor } from "vue2-editor";
import { mdiClose } from '@mdi/js'
import { Drequest } from '@/plugins/Drequest'

export default {
    props: {
        ids: {
            type: String,
            default: 'inbox',
        },
        acceptation: {
            type: Boolean,
            default: true
        },
        isUser: {
            type: Boolean,
            default: true
        }

    },
    data() {
        return {
            dialog: false,
            rfpComment: '',
            loadingReplyBtn: false
        }
    },
    components: {
        VueEditor
    },
    methods: {
        sentPropositionResponse() {
            var bodyFormData = {
                "rfcenterprise": {
                    "comment": this.rfpComment
                }
            }
            const typOfRequest = ""
            var typereq = 'rfcenterprise'
            if (this.$store.getters.getViewrequest.type === 'RFP') {
                typereq = "rfpenterprise"
                bodyFormData = {
                    "rfpenterprise": {
                        "comment": this.rfpComment
                    }
                }
            }
            if (this.$store.getters.getViewrequest.type === 'RFC') {
                if (this.$store.getters.getViewrequest.owner === 'user') {
                    typereq = "rfcuser"
                    bodyFormData = {
                        "rfcuser": {
                            "comment": this.rfpComment
                        }
                    }
                }
            }
            if (this.isBtnAccepted === true) {
                this.loadingReplyBtn = true
                Drequest.api(`${typereq}.accept?id=${this.idr}`)
                    .data(bodyFormData)
                    .raw((response) => {
                        if (response.success === true) {
                            this.$fire({
                                type: "success",
                                text: "Request accepted..!",
                                timer: 3000
                            })
                            this.loadingReplyBtn = false
                            // this.$router.push({ name: 'inbox' })
                            this.$router.go(-1)
                        } else {
                            this.$fire({
                                type: "error",
                                text: "Error occured, please retry later..!",
                                timer: 3000
                            })
                            this.loadingReplyBtn = false
                        }
                    })
                    .catch((err) => {
                        console.log("err")

                        this.loadingReplyBtn = false
                    })
            }
            else if (this.isBtnAccepted === false) {
                this.loadingReplyBtn = true
                Drequest.api(`${typereq}.reject?id=${this.idr}`)
                    .data(bodyFormData)
                    .raw((response) => {
                        if (response.success === true) {
                            this.$fire({
                                type: "success",
                                text: "Request deleted..!",
                                timer: 3000
                            })
                            this.loadingReplyBtn = false
                            // this.$router.push({ name: 'inbox' })
                            this.$router.go(-1)
                        } else {
                            this.$fire({
                                type: "error",
                                text: "Error occured, please retry later..!",
                                timer: 3000
                            })
                            this.loadingReplyBtn = false
                        }
                    })
                    .catch((err) => {
                        console.log("err")

                        this.loadingReplyBtn = false
                    })
            }
            else { }
            this.dialog = false
        }
    },
    setup(props) {
        const idr = props.ids
        const isBtnAccepted = props.acceptation
        const icons = { mdiClose }
        // alert(idr)
        return {
            icons, idr, isBtnAccepted
        }
    }
}
</script>